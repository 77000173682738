import React, { useEffect } from 'react';
import './Configuration.css';
import { Switch, Route, NavLink, useRouteMatch } from "react-router-dom";
import Drawer from '@material-ui/core/Drawer';
import Divider from '@material-ui/core/Divider';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import DailyNews from './DailyNews/DailyNews';
import { Menu, EmojiFlags, PersonAdd, AirplanemodeInactive, Note } from '@material-ui/icons';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Toolbar from '@material-ui/core/Toolbar';
import DataFilters from './DataFilters/DataFilters';
import AddUsers from './AddUsers/AddUsers'
import ContingencyFlightsConfig from './ContingencyFlightsConfig/ContingencyFlightsConfig';
import CriticalAircraftConfig from './CriticalAircraft/CriticalAircraftConfig';
import CriticalFlightsConfig from './CriticalFlightsConfig/CriticalFlightsConfig';
import WeatherConfig from './WeatherConfig/WeatherConfig';
import LatamLogo from '../../vector/latam-logo-icon.svg';
import { firestore, streamUserConfiguration, getDefaultConfiguration } from '../../firebase';
import SvgIcon from '@material-ui/core/SvgIcon';
import { ReactComponent as FilterAlt } from '../../vector/filter-alt.svg';
import { ReactComponent as Assignment } from '../../vector/assignment-late-black.svg';
import { ReactComponent as Agriculture } from '../../vector/agriculture_black_24dp.svg'
import { ReactComponent as BuildCircle } from '../../vector/build-circle-black.svg';
import { ReactComponent as BuildTool } from '../../vector/tool.svg';
import { ReactComponent as Cloudy } from '../../vector/weather/cloudy2.svg';
import { ReactComponent as CrewIcon } from '../../vector/crew-standby.svg';
import OtpConfig from './OtpConfig/OtpConfig';
import GroundSupportConfig from './GroundSupportConfig/GroundSupportConfig';
import { Redirect } from "react-router-dom";
import SummaryLimopsConfig from './SummaryLimopsConfig/SummaryLimopsConfig';
import CrewStandbyConfig from './CrewStandbyConfig/CrewStandbyConfig';

const drawerWidth = '28vh';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex'
  },
  menuButton: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: drawerWidth,
    color: '#150067'
  },
  menuButtonShift: {
    marginLeft: 0,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    color: '#150067'
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#150067',
    height: '9.8vh',
    padding: theme.spacing(0, 1),
    justifyContent: 'center'
  },
  content: {
    height: 'fit-content',
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: drawerWidth,
    paddingLeft: '3.5vh'
  },
  contentShift: {
    height: 'fit-content',
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
    paddingLeft: '3.5vh'
  },
  icon: {
    width: '2.7vh',
    height: 'auto',
  },
  listItem: {
    color: 'inherit',
    minWidth: '0px',
    marginRight: '10%',
    '& svg': {
      color: '#7D7D7D'
    },
  },
  listItemText: {
    fontSize: '1.9vh',
    fontWeight: 'bold'
  }
}));

function Configuration(props) {
  const match = useRouteMatch();
  const classes = useStyles();
  const [open, setOpen] = React.useState(true);
  const [configurationData, setConfigurationData] = React.useState();
  const userEmail = props.user.email;
  const userCCO = props.userCCO;

  // It's CRITICAL to keep [] as the second argument to useEffect. Otherwise, we'll rack up UUUGE firestore bills due to opening a gazillion connections
  useEffect(() => {
    async function defaultConfig(cco) {
      const config = await getDefaultConfiguration(cco);
      return config;
    }
    const unsubscribe = streamUserConfiguration(userEmail, {
      next: (querySnapshot) => {
        if (querySnapshot.data()) {
          setConfigurationData(querySnapshot.data());
        } else {
          defaultConfig(userCCO).then((data) => { setConfigurationData(data ? data : {}); });
        }
      },
      error: (e) => {
        console.error(e);
      }
    });
    return unsubscribe;
  }, [userEmail, userCCO]);

  const handleDrawer = () => {
    setOpen(!open);
  };

  const updateConfigurationData = async (config, configData) => {
    configurationData[config] = configData;
    await firestore.collection('videowall-configuration').doc(userEmail).set(configurationData);
    setConfigurationData(configurationData);
    return true;
  }

  return (
    <div className="configuration">
      {/* Toolbar */}
      <Toolbar>
        <IconButton
          color="inherit"
          aria-label="handle menu"
          onClick={handleDrawer}
          className={open ? classes.menuButton : classes.menuButtonShift}
        ><Menu />
        </IconButton>
      </Toolbar>

      {/* Menu drawer */}
      <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="left"
        open={open}
        classes={{ paper: classes.drawerPaper }}
      >

        <a href="/options">
          <div className={classes.drawerHeader}>
            <img src={LatamLogo} alt="error"></img>
            <span className='header-title'>Video Wall</span>
          </div>
        </a>

        <Divider />
        <div className="subtitle">
          <span>General Configuration</span>
        </div>
        <Divider />
        <div className="menu-list">
          {!props.isUserAdmin &&
            <ListItem button component={NavLink} className="menu-item" activeClassName="is-active" to={`${match.url}/data-filters`}>
              <ListItemIcon className={classes.listItem}><SvgIcon component={FilterAlt} className={classes.icon} /></ListItemIcon>
              <ListItemText disableTypography={true} className={classes.listItemText}>General Data Filters</ListItemText>
            </ListItem>
          }
          {props.isUserAdmin &&
            <ListItem button component={NavLink} className="add-users-item" activeClassName="is-active" to={`${match.url}/users`}>
              <ListItemIcon className={classes.listItem}><SvgIcon component={PersonAdd} className={classes.icon} /></ListItemIcon>
              <ListItemText disableTypography={true} className={classes.listItemText}>Add Users</ListItemText>
            </ListItem>
          }
          {!props.isUserAdmin && props.canEditNews &&
            <ListItem button component={NavLink} className="daily-news-item" activeClassName="is-active" to={`${match.url}/daily-news`}>
              <ListItemIcon className={classes.listItem}><SvgIcon component={Note} className={classes.icon} /></ListItemIcon>
              <ListItemText disableTypography={true} className={classes.listItemText}>Daily News</ListItemText>
            </ListItem>
          }
        </div>
        {!props.isUserAdmin &&
          <>
            <div className="subtitle">
              <span>Section Configuration</span>
            </div>
            <Divider />
            <div>
              <ListItem button component={NavLink} activeClassName="is-active" to={`${match.url}/contingency-flights`}>
                <ListItemIcon className={classes.listItem}><AirplanemodeInactive className={classes.icon} /></ListItemIcon>
                <ListItemText disableTypography={true} className={classes.listItemText}>Contingency Flights</ListItemText>
              </ListItem>
              <ListItem button component={NavLink} activeClassName="is-active" to={`${match.url}/otp`}>
                <ListItemIcon className={classes.listItem}><SvgIcon component={EmojiFlags} className={classes.icon} /></ListItemIcon>
                <ListItemText disableTypography={true} className={classes.listItemText}>OTP</ListItemText>
              </ListItem>
              <ListItem button component={NavLink} activeClassName="is-active" to={`${match.url}/critical-flights`}>
                <ListItemIcon className={classes.listItem}><SvgIcon component={Assignment} className={classes.icon} /></ListItemIcon>
                <ListItemText disableTypography={true} className={classes.listItemText}>Critical Flights</ListItemText>
              </ListItem>
              <ListItem button component={NavLink} activeClassName="is-active" to={`${match.url}/ground-support`}>
                <ListItemIcon className={classes.listItem}><SvgIcon component={Agriculture} className={classes.icon} /></ListItemIcon>
                <ListItemText disableTypography={true} className={classes.listItemText}>Ground Support</ListItemText>
              </ListItem>
              <ListItem button component={NavLink} activeClassName="is-active" to={`${match.url}/weather`}>
                <ListItemIcon className={classes.listItem}><SvgIcon component={Cloudy} className={classes.icon} /></ListItemIcon>
                <ListItemText disableTypography={true} className={classes.listItemText}>Weather</ListItemText>
              </ListItem>
              <ListItem button component={NavLink} activeClassName="is-active" to={`${match.url}/critical-aircraft`}>
                <ListItemIcon className={classes.listItem}><SvgIcon component={BuildCircle} className={classes.icon} /></ListItemIcon>
                <ListItemText disableTypography={true} className={classes.listItemText}>Critical Aircraft</ListItemText>
              </ListItem>
              <ListItem button component={NavLink} activeClassName="is-active" to={`${match.url}/summary-limops`}>
                <ListItemIcon className={classes.listItem}><SvgIcon component={BuildTool} className={classes.icon} /></ListItemIcon>
                <ListItemText disableTypography={true} className={classes.listItemText}>Summary Limops</ListItemText>
              </ListItem>
              <ListItem button component={NavLink} activeClassName="is-active" to={`${match.url}/crew-standby`}>
                <ListItemIcon className={classes.listItem}><SvgIcon component={CrewIcon} className={classes.icon} /></ListItemIcon>
                <ListItemText disableTypography={true} className={classes.listItemText}>Crew Standby</ListItemText>
              </ListItem>
            </div>
          </>
        }
     {/*props.userRole === 'SUPER USER' || props.isUserCrewStandby ? (
      <ListItem button component={NavLink} activeClassName="is-active" to={`${match.url}/crew-standby`}>
        <ListItemIcon className={classes.listItem}>
          <SvgIcon component={CrewIcon} className={classes.icon} />
        </ListItemIcon>
        <ListItemText disableTypography={true} className={classes.listItemText}>Crew Standby</ListItemText>
      </ListItem>
      ) : null*/}
      </Drawer>

      {/* Configuration views */}
      {configurationData && (
        <div className={open ? classes.content : classes.contentShift}>
          <Switch>
            <Route exact path={match.path}>
            </Route>
            <Route path={`${match.path}/data-filters`}>
              {!props.isUserAdmin ? <DataFilters configurationData={configurationData.dataFiltersConfig} updateConfigurationData={updateConfigurationData} /> : <Redirect to='/configuration' />}
            </Route>
            <Route path={`${match.path}/daily-news`}>
              {!props.isUserAdmin && props.canEditNews ? <DailyNews userCCO={userCCO} /> : <Redirect to='/configuration' />}
            </Route>
            <Route path={`${match.path}/users`}>
              {props.isUserAdmin ? <AddUsers /> : <Redirect to='/configuration' />}
            </Route>
            <Route path={`${match.path}/contingency-flights`}>
              {!props.isUserAdmin ? <ContingencyFlightsConfig configurationData={configurationData.contingencyFlightsConfig} updateConfigurationData={updateConfigurationData} /> : <Redirect to='/configuration' />}
            </Route>
            <Route path={`${match.path}/critical-flights`}>
              {!props.isUserAdmin ? <CriticalFlightsConfig configurationData={configurationData.criticalFlightsConfig} updateConfigurationData={updateConfigurationData} /> : <Redirect to='/configuration' />}
            </Route>
            <Route path={`${match.path}/ground-support`}>
              {!props.isUserAdmin ? <GroundSupportConfig configurationData={configurationData.groundSupportConfig} updateConfigurationData={updateConfigurationData} /> : <Redirect to='/configuration' />}
            </Route>
            <Route path={`${match.path}/weather`}>
              {!props.isUserAdmin ? <WeatherConfig configurationData={configurationData.weatherConfig} updateConfigurationData={updateConfigurationData} /> : <Redirect to='/configuration' />}
            </Route>
            <Route path={`${match.path}/critical-aircraft`}>
              {!props.isUserAdmin ? <CriticalAircraftConfig
                configurationData={configurationData.criticalAircraftConfig}
                updateConfigurationData={updateConfigurationData}
                configurationCheckbox={configurationData} /> : <Redirect to='/configuration' />}
            </Route>
            <Route path={`${match.path}/summary-limops`}>
              {!props.isUserAdmin ? <SummaryLimopsConfig isUserLimops={props.isUserLimops} userconfigurationData={configurationData.summaryLimopsConfig} updateConfigurationData={updateConfigurationData} /> : <Redirect to='/configuration' />}
            </Route>
            <Route path={`${match.path}/crew-standby`}>
              {props.userRole === 'SUPER USER' || props.isUserCrewStandby ? (
                <CrewStandbyConfig isUserLimops={props.isUserLimops} userCCO={props.userCCO} userconfigurationData={configurationData.summaryLimopsConfig} updateConfigurationData={updateConfigurationData} configurationCheckbox={configurationData} />
              ) : (<Redirect to='/configuration' /> )
              }
            </Route>
            <Route path={`${match.path}/otp`}>
              {!props.isUserAdmin ? <OtpConfig configurationData={configurationData.otpConfig} updateConfigurationData={updateConfigurationData} /> : <Redirect to='/configuration' />}
            </Route>
          </Switch>
        </div>
      )}
    </div>
  );
}

export default Configuration;